import { Box } from "@chakra-ui/react";
const index = ({
  py,
  px,
  mt,
  ml,
  mr,
  color,
  title,
  width,
  handleClick,
  backgroundColor,
}) => {
  return (
    <Box
      as="button"
      height="45px"
      width={width}
      borderWidth={0}
      fontSize="16px"
      mr={mr ? mr : 0}
      ml={ml ? ml : 0}
      mt={mt ? mt : 0}
      py={py ? py : 0}
      px={px ? px : 0}
      fontWeight="700"
      borderRadius="4px"
      bg={backgroundColor}
      onClick={handleClick}
      color={color ? color : "white"}
      _hover={{
        backgroundColor:
          backgroundColor && backgroundColor === "golden" ? "maroon" : "golden",
      }}
    >
      {title}
    </Box>
  );
};
export default index;
